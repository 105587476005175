import React from 'react';
// import PropTypes from 'prop-types';
// import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { SignUpForm } from 'layouts';
import { BASE_URL } from 'config';
import { mapStateToProps } from 'utilities';

const SignUpPage = () => (
  <Layout
    page="user/sign-up"
    meta={{
      title: 'Sign up',
    }}
  >
    <Helmet
      title="Sign Up to Manage Your Best New Zealand Vacation | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: 'Create an account so you can easily save and keep your tour plans together.',
        },
        { name: 'og:title', content: 'Sign Up to Manage Your Best New Zealand Vacation | Haka Tours, NZ' },
        {
          name: 'og:description', content: 'Create an account so you can easily save and keep your tour plans together.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/user/sign-up/` }]}
    />

    <SignUpForm />
  </Layout>
);

// SignUpPage.propTypes = {
//   user: PropTypes.object.isRequired,
// };

export default connect(mapStateToProps)(SignUpPage);
